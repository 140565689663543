<template>
  <div class="lookingInvestmentContainer">
    <wx-open-launch-weapp id="launch-btn" appid="wx32179c593cd630b9" username="gh_49b0dbd8ce89">
      <script type="text/wxtag-template">
        <style>.btn {width: 100%; text-align: center;}</style>
        <div class="btn">跳转小程序</div>
      </script>
    </wx-open-launch-weapp>
  </div>
</template>

<script>
import { request } from '@/utils/request';
import wx from 'weixin-js-sdk';
export default {
  data() {
    return {
      url: ""
    };
  },
  mounted() {
    this.weixinConfig();
  },
  created() {
    var url = this.$route.query.url;
    console.log(url);
  },
  methods: {
    weixinConfig() {
      let url = location.href.split('#')[0];
      console.log(url);
      request("http://wxgb.thinkervc.cn/prod-api/visitor/wechat/geDjsShareData?url=" + url).then(res => {
        console.log(res);
        console.log(res.appid);
        wx.config({
          debug: true,
          appId: res.appid,
          timestamp: res.timestamp,
          nonceStr: res.nonceStr,
          signature: res.signature,
          jsApiList: [
            'onMenuShareTimeline', 'onMenuShareAppMessage', 'onMenuShareQQ', 'onMenuShareWeibo',
            'onMenuShareQZone', 'previewImage', 'hideOptionMenu', 'showOptionMenu', 'chooseWXPay',
            'chooseImage', 'openLocation', 'openAddress'
          ],
          openTagList: ['wx-open-launch-weapp']
        });
        wx.ready(function(res) {
          console.log('回调成功', res);
        });
        wx.error(function(res) {
          console.log('回调失败', res);
        })
      })
    }
  },
  components: {}
};
</script>

<style scoped lang="scss"></style>
